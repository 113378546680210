<!--  数据服务 -->
<template>
  <div class="dataServices">
    <product-banner :bannerImg="bannerImg" :bannerTitleData="bannerTitleData" />
    <cases-title :titleVal="'数据服务'" />
    <div class="dataServices-container">
      <div class="first-text">宝晟云平台数据处理架构，采用NLP自然语言处理技术，构建数据集和元数据体系，通过数据采集、数据清洗以及结构化转换，进行数据整合、数据挖掘。</div>
      <div class="img">
        <img src="@/assets/images/products/dataServices-img.png" alt="">
        <div>例图:宝晟云平台数据处理架构</div>
      </div>
      <div class="last-text">{{ `宝晟云平台数据处理采用微服务技术架构，构建数据标准与数据质量管理体系，兼容多种数据库来源的数据采集，采用Python等多种工具对数据进行清洗以及结构化转
              换，构建数据中心。通过平台数据库引擎，为用户提供数据检索、数据管理、数据分析和可视化等一系列数据服务。宝晟云平台可集成X-极智分析系统，嵌入100余种
              统计分析方法，为用户提供高效、智能的科研数据分析工具。平台可集成Tensorflow深度学习框架，宝晟数据服务团队利用特征工程创新算法，为用户提供数据挖掘、
              数据建模服务。`| myTrim
      }}</div>
    </div>
  </div>
</template>

<script>
import productBanner from './components/ProductsBanner.vue'
import casesTitle from "@/views/cases/components/Title.vue"
export default {
  components: {
    productBanner,
    casesTitle
  },
  data() {
    return {
      bannerImg: require("@/assets/images/products/products-dataServices.png"),
      bannerTitleData: {
        zn: "数据服务",
        en: "Data Solutions & Services"
      }
    }
  }
}
</script>

<style lang="less" scoped>
.dataServices-container {
  width: 1200px;
  margin: 0 auto 44px;


  .first-text {
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    line-height: 22px;
    text-align: justify;
  }

  .img {
    text-align: center;
    margin: 54px 0 44px;


  }

  .last-text {
    font-size: 16px;
    font-family: PingFang SC-Regular,
      PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 30px;
    text-align: justify;
  }

  // letter-spacing: 20px;
}
</style>